module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","name":"transformation-evolt.io","short_name":"transformation","start_url":"/","background_color":"#FFF","theme_color":"#0555FF","display":"standalone","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c828da93fce82deb4a698d4b6365adfd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","fr"],"redirect":true,"defaultLanguage":"en","siteUrl":"https://transfo.evolt.io","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"OqhFWeCMT4rrCToEdSgvp1EyUVrVDRE3","devKey":"OqhFWeCMT4rrCToEdSgvp1EyUVrVDRE3","trackPageDelay":50,"manualLoad":true,"trackPage":true,"delayLoadTime":1000},
    }]
